//图片oss地址
const uploadOss = "https://climatebridge-erp.oss-cn-shanghai.aliyuncs.com/dev/green-id/"
const certificateUrl = "https://sandbox.evident.dev"
// const downloadPdf = 'http://101.133.142.170/certification.html'
//  const downloadPdf = 'http://139.224.203.135/certification.html'

 const downloadPdf = 'http://mall.climatebridge.com/pc/certification.html'

function addCommas(nStr) {
    nStr += '';
    var x = nStr.split('.');
    var x1 = x[0];
    var x2 = x.length > 1 ? '.' + x[1] : '';
    var rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
        x1 = x1.replace(rgx, '$1' + ',' + '$2');
    }
    return x1 + x2;
}


export default {
    uploadOss,
    certificateUrl,
    downloadPdf,
    addCommas
};