<template>

  <body>
    <Header></Header>
    <div class="content">
      <div class="bread_nav flex-box">
        <div class="bread fs16 col3">
          当前位置：<router-link to="/">首页</router-link>><router-link to="/info">个人中心</router-link>><a
            href="javascript:;">基本信息</a>
        </div>
      </div>
      <div class="personal per_info pt30 m1440 flex-box flex-col-start">
        <div class="left_nav fs18">
          <router-link to="/info" class="active">基本信息</router-link>
          <router-link to="/certificate">证书抬头</router-link>
          <router-link to="/maintain_list">开票信息</router-link>
          <router-link to="/address_list">收件地址</router-link>
          <router-link to="/invoices_status">发票管理</router-link>
          <!-- mao 20250324 -->
          <!-- <router-link to="/contract_management">合同管理</router-link> -->
          <!-- end -->
          <router-link to="/change_password">修改密码</router-link>
          <router-link to="/change_phone">修改手机号</router-link>
          <a @click="cancellation()" class="cursor" href="javascript:;">删除账户</a>
          <!-- <router-link to="/per_about">关于我们</router-link> -->
        </div>
        <div class="right_box flex-grow-1">
          <div class="fs18 fwb pb30">基本信息</div>
          <div class="flex-box pb30">
            <div class="fs16 title">头像</div>
            <!-- <div class="avatar" :style="{ backgroundImage: 'url(' + userinfo.avatar!=null?userinfo.avatar:avatar + ')' }"> -->
            <div class="avatar" v-if="oss_avatar == ''" :style="{ backgroundImage: 'url(' + avatar + ')' }">
              <input type="file" ref="inputer" @change="chooseAvatar" multiple
                accept="image/png,image/jpeg,image/gif,image/jpg" />
            </div>
            <div class="avatar" v-else :style="{ backgroundImage: 'url(' + oss + oss_avatar + ')' }">
              <input type="file" ref="inputer" @change="chooseAvatar" multiple
                accept="image/png,image/jpeg,image/gif,image/jpg" />
            </div>
          </div>
          <div class="flex-box pb30">
            <div class="fs16 title">真实姓名</div>
            <input type="text" v-model="userinfo.name" class="phone fs16" placeholder="请输入真实姓名" />
          </div>
          <div class="flex-box pb30">
            <div class="fs16 title">昵称</div>
            <input type="text" v-model="userinfo.nick_name" class="phone fs16" placeholder="请输入昵称" />
          </div>
          <div class="flex-box border pb30">
            <div class="fs16 title">手机</div>
            <input type="text" disabled v-model="userinfo.tel" class="phone fs16" placeholder="请输入手机号" />
          </div>
          <div class="pt40 fs18 fwb pb30">公司信息</div>
          <div class="flex-box pb25 fs18">
            <div class="title">名称</div>
            <input type="text" class="address fs16" v-model="userinfo.companyName" placeholder="请输入公司名称" />
          </div>
          <div class="flex-box pb25 fs18">
            <div class="title">名称(英文)</div>
            <input type="text" class="address fs16" v-model="userinfo.companyNameEn" placeholder="请输入公司名称(英文)" />
          </div>
          <div class="flex-box pb30">
            <div class="fs18 title">营业执照号</div>
            <input type="text" class="address fs16" v-model="userinfo.businessLicense" placeholder="请输入营业执照号" />
          </div>
          <div class="flex-box pb30">
            <div class="fs18 title">地址</div>
            <input type="text" class="address fs16" v-model="userinfo.companyAddress" placeholder="请输入地址" />
          </div>
          <div class="flex-box pb30">
            <div class="fs18 title">联系人</div>
            <input type="text" class="address fs16" v-model="userinfo.contactor" placeholder="请输入联系人" />
          </div>
          <div class="flex-box pb30">
            <div class="fs18 title">电话</div>
            <input type="text" class="address fs16" v-model="userinfo.contactorTel" placeholder="请输入电话" />
          </div>
          <div class="flex-box pb30">
            <div class="fs18 title">邮箱</div>
            <input type="text" class="address fs16" disabled v-model="userinfo.contactorEmail" placeholder="请绑定邮箱" />
            <div class="btn1" v-if="userinfo.emailChecked == 0 && send_status == 0" @click="showMail()">绑定</div>
            <!-- <div class="btn2" v-if="userinfo.emailChecked == 1">已验证</div> -->
            <div class="btn2" v-if="userinfo.emailChecked == 1" @click="showMail()">更换</div>
          </div>
          <!-- <div class="flex-box pb30" v-if="send_status == 1">
            <div class="fs18 title">邮箱验证码</div>
            <input type="text" class="address fs16" v-model="userinfo.emailCode" placeholder="请输入邮箱验证码" />
          </div> -->
          <!-- <div class="flex-box pb25 fs18">
            <div class="title">受益人</div>
            <div>李薇薇</div>
          </div>
          <div class="flex-box pb25 fs18">
            <div class="title">绿证抬头</div>
            <div>Environmental Bridge Biotechnology Co., Ltd</div>
            <div class="pl30 fs16 col9">HuanjingqiaoShenwukejiyouxiangongsi</div>
          </div>
          <div class="flex-box pb25 fs18">
            <div class="title">发票抬头</div>
            <div>南通春秋软件科技有限公司</div>
          </div> -->
      
          <div class="save fs16" @click="submit">保存</div>
        </div>
      </div>
    </div>
    <!-- 绑定邮箱 -->
    <Popup :visible="mailPopup" :confirmText="false" :cancleText="false" @close="cancelMail">
      <div class="name_pop" style="width:auto;">
        <div class="flex-box name_title flex-between">
          <div class="fs20">绑定邮箱</div>
          <img src="../assets/static/icon/icon_close.png" class="icon_close" @click="cancelMail()" alt="" />
        </div>
        <div class="cirticle_info">
          <div class="must fs16 col9 info_title">新邮箱</div>
          <input type="text" class="input1" @input="inputEmail" placeholder="请输入邮箱" />
          <div class="must fs16 col9 info_title">验证码</div>
          <div class="flex-box">
            <input type="text" class="input1" v-model="userinfo.emailCode" placeholder="请输入邮箱验证码"
              style="width:276px;" />
            <div class="code_btn" v-if="send_status == 0" @click="send_email">获取验证码</div>
            <div class="code_btn" v-if="send_status == 1 && count > 0">{{ count }}s</div>
            <div class="code_btn" v-if="send_status == 1 && count <= 0" @click="send_email">重新发送</div>
          </div>
          <div class="flex-box flex-between">
            <div class="cancel" @click="cancelMail()">取消</div>
            <div class="save" @click="bindEmail()">保存</div>
          </div>
        </div>
      </div>
    </Popup>
    <Footer></Footer>
  </body>
</template>
<script>
import cookie from "vue-cookies";
import axios from "axios";
import { regionData, CodeToText } from "element-china-area-data";
import { Loading, Message, MessageBox } from "element-ui"; // 消息提示
import Pagination from "@/components/pagination.vue";
export default {
  components: {
    Pagination,
  },
  name: "info",
  data() {
    return {
      avatar: require("../assets/static/images/avatar.png"),
      popup: false,
      userinfo: {},
      navIndex: 1,
      oss_avatar: '',
      oss: '',
      options: regionData,
      selectedOptions: [],
      current: {
        receiverName: '',
        receiverTel: '',
        addressDetail: '',
        status: '0',
        region: ''
      },
      send_status: 0, //验证是否发送
      count: 60,
      email: '',
      mailPopup: false,
    };
  },

  created() {
    this.oss = this.global.uploadOss
    this.get_user_info()
  },
  methods: {

    bindEmail() {
      let that = this
      let user = that.userinfo
      let email = that.email
      if (!email) {
        that.$message.error('邮箱不能为空')
        return;
      }
      var reg = /^\w+((-\w+)|(\.\w+))*\@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z0-9]+$/;
      if (!reg.test(email)) {
        that.$message.error('请填写正确的邮箱')
        return;
      }
      if (email == user.contactorEmail) {
        that.$message.error('新邮箱和旧邮箱一致，无须修改')
        return;
      }
      if (!user.emailCode) {
        that.$message.error('请输入邮箱验证码')
        return;
      }
      let s_data = {
        emailCode: user.emailCode,
        contactorEmail: email,
        id: user.id,
        emailChecked: 0
      }
      that.$api.updateUserInfo(s_data).then((res) => {
        if (res.resCode == 0) {
          that.send_status = 0
          that.mailPopup = !that.mailPopup
          that.email = ''
          that.userinfo.emailCode = ''
          that.$message.success('更新成功');
          that.get_user_info()
        } else {
          that.$message.error(res.resMsg);
        }
      });
    },

    inputEmail(e) {
      this.email = e.target.value
    },

    showMail() {
      this.mailPopup = !this.mailPopup
      this.send_status = 0
      this.email = ''
      this.userinfo.emailCode = ''
    },

    cancelMail() {
      this.send_status = 0
      this.email = ''
      this.userinfo.emailCode = ''
      this.mailPopup = !this.mailPopup
    },

    //发送验证邮箱
    send_email() {
      let that = this
      let user = that.userinfo
      let email = that.email
      if (!email) {
        that.$message.error('邮箱不能为空')
        return;
      }
      if (email == user.contactorEmail) {
        that.$message.error('新邮箱和旧邮箱一致，无须修改')
        return;
      }
      var reg = /^\w+((-\w+)|(\.\w+))*\@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z0-9]+$/;
      if (!reg.test(email)) {
        that.$message.error('请填写正确的邮箱')
        return;
      }
      if (user.name) {
        var name = user.name
      } else {
        var name = '环保桥用户'
      }
      that.$api.sendEmailCode({ email: email, name: name }).then((res) => {
        if (res.resCode == 0) {
          that.$message.success('验证码已发送');
          const TIME_COUNT = 60;
          if (!that.timer) {
            that.send_status = 1;
            that.count = TIME_COUNT;
            that.timer = setInterval(() => {
              if (that.count > 0 && that.count <= TIME_COUNT) {
                that.count--;
              } else {
                clearInterval(that.timer);
                that.timer = null;
              }
            }, 1000);
          }
        } else {
          that.send_status = 0
          that.$message.error(res.resMsg);
        }
      }).catch((err) => {
        console.log(err);
      });

    },

    //修改基本信息
    submit() {
      let that = this
      let user = that.userinfo
      let tel = user.tel
      if (tel != '' && tel != null) {
        let iphoneReg = /^1[3456789]\d{9}$/;
        if (!iphoneReg.test(tel)) {
          that.$message.error('手机号码格式不正确')
          return;
        }
      }

      if (user.contactorTel != '' && user.contactorTel != null) {
        var patrn = /^1[3456789]\d{9}$/; //手机电话
        var zjdh = /^((0\d{2,3})-)?(\d{7,8})$/; //座机电话
        if (patrn.test(user.contactorTel) == false && zjdh.test(user.contactorTel) == false) {
          that.$message.error("请输入正确的联系电话！");
          return;
        }
      }

      // console.log(user); return;
      if (user.emailChecked == 0) {
        that.$message.error('请完成邮箱验证')
        return;
      }
      let oss_avatar = that.oss_avatar
      if (oss_avatar != '' && oss_avatar != null) {
        user.avatar = oss_avatar
      }
      for (var i in user) {
        if (user[i] === '' || user[i] === null || user[i] === undefined) {
          delete user[i];
        }
      }
      that.$api.editUserInfo(user).then((res) => {
        if (res.resCode == 0) {
          that.send_status = 0
          let up_user = cookie.get("huanbaoqiao_userinfo");
          if (up_user.tel != user.tel) {
            that.$message.success('手机号已更改,请重新登录');
            cookie.remove("huanbaoqiao_userinfo")
            cookie.remove("huanbaoqiao_token")
            that.$router.push("/login")
          } else {
            that.$message.success('更新成功');
            that.get_user_info()
            if (oss_avatar != '' && oss_avatar != null && oss_avatar != up_user.avatar) {
              location.reload()
            }
            if (user.nick_name != '' && user.nick_name != null && user.nick_name != up_user.nick_name) {
              location.reload()
            }
          }
        } else {
          that.$message.error(res.resMsg);
        }
      });
    },

    //更换头像
    chooseAvatar(e) {
      let oss = this.global.uploadOss
      console.log(e.target.files)//这个就是选中文件信息
      let formdata = new FormData()
      Array.from(e.target.files).map(item => {
        console.log(item)
        formdata.append("file", item)  //将每一个文件图片都加进formdata
      })
      axios.post(axios.defaults.baseURL + "v1/cdn/ali/upload", formdata).then(res => {
        console.log(res)
        if (res.data.resCode == 0) {
          this.oss_avatar = res.data.root
        } else {
          this.$message.error(res.data.resMsg);
        }
        console.log(res)
      })
    },

    get_user_info() {
      let that = this;
      let user = cookie.get("huanbaoqiao_userinfo");
      let token = cookie.get("huanbaoqiao_token");
      if (!user || !token) {
        this.$message.error('请先登录');
        this.$router.push("/login")
        return;
      }
      that.$api.apiUserInfo({ deviceType: '1', id: user.id ? user.id : 0 }).then((res) => {
        console.log(res)
        if (res.resCode == 0) {
          // console.log(res.root)
          if (res.root[0].avatar != null && res.root[0].avatar != '') {
            that.oss_avatar = res.root[0].avatar
          }
          cookie.set("huanbaoqiao_userinfo", res.root[0])
          that.userinfo = res.root[0]
        } else {
          that.$message.error(res.resMsg);
        }
      });
    },

    //删除
    cancellation() {
      let that = this;
      MessageBox.confirm("是否确定删除当前账户?", "提示", { type: "warning" })
        .then((res) => {
          // 判断 是否是 确认
          if (res == "confirm") {
            let userinfo = cookie.get("huanbaoqiao_userinfo");
            that.$api.logOffAccount({ userId: userinfo.id }).then((res) => {
              if (res.resCode == 0) {
                that.$message.success('删除成功');
                cookie.remove("huanbaoqiao_token");
                cookie.remove("huanbaoqiao_userinfo");
                setTimeout(function () {
                  that.$router.push("/");
                }, 800)
              } else {
                that.$message.error(res.resMsg);
              }
            });

          }
        })
        .catch((err) => {
          //    console.log(err);
        });
    },

    close() {

    }
  },
};
</script>
